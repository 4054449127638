<template>
  <div class="container">
    <div class="tip-box">
      加盟店 決済取消画面 <br />
      シミュレーション用
    </div>
    <div class="header">
      <van-icon name="close" />
      <h4>Payment Cancel!</h4>
      <p>Please pay again</p>
    </div>
  </div>
</template>

<script>
import { openGet } from '@/apis'
import { isMobile } from '@/utils'

export default {
  name: 'Cancel',
  data() {
    return {
      data: {
        currencyType: '',
        orderPrice: '',
      },
    }
  },
  created() {
    if (isMobile) {
      this.$router.replace('/m/fail')
      return
    }
    // this.getConsultPayment()
  },
  methods: {
    getConsultPayment() {
      const clientOrderNo = localStorage.getItem('clientOrderNo') || ''
      openGet({ clientOrderNo }).then((data) => {
        this.data = data
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 1080px;
  margin: 60px auto;
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 26px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
</style>
